import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Workout 18.5 = 12.5 = 11.6`}</strong></p>
    <p>{`Complete as many reps as possible in 7 minutes of:\\
3 thrusters (100/65)\\
3 chest-to-bar pull-ups\\
6 thrusters\\
6 chest-to-bar pull-ups\\
9 thrusters\\
9 chest-to-bar pull-ups\\
12 thrusters\\
12 chest-to-bar pull-ups\\
15 thrusters\\
15 chest-to-bar pull-ups\\
18 thrusters\\
18 chest-to-bar pull-ups`}</p>
    <p>{`This is a timed workout. If you complete the round of 18, go on to 21.
If you complete 21, go on to 24, etc.`}</p>
    <p>{`*`}{`*`}{`Bonus Class WOD`}{`*`}{`*`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`12 Calorie Row`}</p>
    <p>{`12 Pushups`}</p>
    <p>{`12 V Ups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Judging for 18.5 will take place today during the 9:00 & 10:00am
classes as well as from 11:00-12:00.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      